import {Footer, Grid, Image, Divider, Card, Popup, Avatar, List} from "antd-mobile";
import {useNavigate} from "react-router-dom";
import { retrieveRawInitData } from '@telegram-apps/sdk';
import './index.css'
import {useEffect, useState} from "react";
import {ajaxH5ConfigGetInfo} from "../../../api";
import {Spin} from "antd";
import storeage from "../../../utils/storage";

export default function Index() {
    const [info, setInfo] = useState({});
    const [infoUser, setInfoUser] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingService, setLoadingService] = useState(false);
    const navigate = useNavigate()
    const demoSrc =
        'https://images.unsplash.com/photo-1567945716310-4745a6b7844b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=60'

    let lp;
    if (process.env.REACT_APP_PROXY_URL === 'build') {
         lp = retrieveRawInitData();
    }

    useEffect(() => {
        let paramsObject = {};
        if (process.env.REACT_APP_PROXY_URL === 'build') {
            const params = new URLSearchParams(lp)

            for (const [key, value] of params.entries()) {
                paramsObject[key] = value;
            }
        }else{
             paramsObject = {
                auth_date: "1742629451",
                chat_instance: "-2244524810231913842",
                chat_type: "supergroup", start_param: "10003",
                hash: "71c1e81b02442c7bc2f0ef5455bb1c80a329ef8fffabe43b153546034fe76dd4",
                signature: "UJj1n4_xbRMFwEH86P0XcOiGUKKiNkES_RLsIdnR3LRCDpD0tmdP0fI9tXPzfOd2FOF5ElXyFt07ZY0X_zhTBQ",
                user: "{\"id\":6266243096,\"first_name\":\"跳舞💃\",\"last_name\":\"\",\"username\":\"Urx24h\",\"language_code\":\"zh-hans\",\"allows_write_to_pm\":true,\"photo_url\":\"https:\/\/t.me\/i\/userpic\/320\/G6jht99UVBCv3HtDWtddFoMj76raSg8QynroXyfiz2T2so74c5iwnfuVsB5johaR.svg\"}",
            }
        }
        console.log("paramsObject",paramsObject)
        const user = JSON.parse(paramsObject.user);
        setInfoUser(user)
        fetch({orgid:paramsObject.start_param})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const fetch = async (params = {}) => {
        let config = storeage.getKey("config")
        console.log("config",config)
        if (config == null || config === "undefined" || config === "") {
            setLoading(true);
            let result = await ajaxH5ConfigGetInfo(params)
            if (result.code === 1) {
                setInfo(result.data);
                // message.success('获取成功')
            }
            setLoading(false);
            storeage.setKey("config", result.data);
        }else{
            setInfo(config);
        }

    }

    return (
        <>
            <Spin spinning={loading}>
            {/*<NoticeBar content={"👩‍💻联系客服:"+info.bot_service_tg} color='info' closeable/>*/}
                <Popup
                    visible={loadingService}
                    onMaskClick={() => {
                        setLoadingService(false)
                    }}
                    bodyStyle={{
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        minHeight: '20vh',
                        padding: '10px',
                    }}
                >
                    👩‍💻联系客服: {info.bot_service_tg}
                </Popup>
            <Divider>{info.bot_name}</Divider>
                <Image src={"/img/11.png"} />
                <List>
                    <List.Item
                        prefix={<Avatar src={infoUser.photo_url} />}
                        description={infoUser.username}
                    >
                        {infoUser.first_name}
                    </List.Item>
                </List>



            <Card title='功能' background='gray'>
                <Grid columns={3} gap={8}>
                    <Grid.Item>
                        <div className="grid-item-block" onClick={()=>{ navigate(`/h5/exchange-trx`)}}>
                            <div className="grid-item-block-img"><Image src={"/img/1.png"} width={64} height={64} fit='cover' style={{borderRadius: 32}}/></div>
                            <div className="grid-item-block-text">🔥闪兑TRX</div>
                        </div>
                    </Grid.Item>
                    <Grid.Item>
                        <div className="grid-item-block" onClick={()=>{ navigate(`/h5/tenancy`)}}>
                            <div className="grid-item-block-img"><Image src={"/img/2.png"} width={64} height={64} fit='cover' style={{borderRadius: 32}}/></div>
                            <div className="grid-item-block-text">🔋能量闪租</div>
                        </div>
                    </Grid.Item>
                    <Grid.Item>
                        <div className="grid-item-block" onClick={()=>{ navigate(`/h5/tenancy-pen`)}}>
                            <div className="grid-item-block-img"><Image src={"/img/3.png"} width={64} height={64} fit='cover' style={{borderRadius: 32}}/></div>
                            <div className="grid-item-block-text">✏️笔数套餐</div>
                        </div>
                    </Grid.Item>
                    <Grid.Item>
                        <div className="grid-item-block" onClick={()=>{ setLoadingService(true)}}>
                            <div className="grid-item-block-img"><Image src={"/img/4.png"} width={64} height={64} fit='cover' style={{borderRadius: 32}}/></div>
                            <div className="grid-item-block-text">👩‍💻联系客服</div>
                        </div>
                    </Grid.Item>
                    <Grid.Item>
                        <div className="grid-item-block">
                            <div className="grid-item-block-img"><Image src={demoSrc} width={64} height={64} fit='cover' style={{borderRadius: 32}}/></div>
                            <div className="grid-item-block-text">生活缴费5</div>
                        </div>
                    </Grid.Item>
                    <Grid.Item>
                        <div className="grid-item-block">
                            <div className="grid-item-block-img"><Image src={demoSrc} width={64} height={64} fit='cover' style={{borderRadius: 32}}/></div>
                            <div className="grid-item-block-text">生活缴费6</div>
                        </div>
                    </Grid.Item>
                    <Grid.Item>
                        <div className="grid-item-block">
                            <div className="grid-item-block-img"><Image src={demoSrc} width={64} height={64} fit='cover' style={{borderRadius: 32}}/></div>
                            <div className="grid-item-block-text">生活缴费7</div>
                        </div>
                    </Grid.Item>
                </Grid>
            </Card>
            <Footer className={"element"} content='@ 2004-2020 Alipay.com All rights reserved'></Footer>
            </Spin>
        </>
    )
}