import {NavBar, Divider, Toast,Card} from "antd-mobile";

import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import storeage from "../../../utils/storage";
import {ajaxH5ConfigGetBaseConfig} from "../../../api";
import {copyTextToClipboard} from "../../../utils/define";
export default function Index() {
    const [info, setInfo] = useState(false);
    const [baseConfig, setBaseConfig] = useState(false);
    const navigate = useNavigate()
    document.title = '新的页面标题';
    useEffect(() => {
        let config = storeage.getKey("config")
        setInfo(config);
        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const fetch = async (params = {}) => {
        Toast.show({
            content: '等待加载数据...',
            position: 'bottom',
            duration: 0,
        })
        let result = await ajaxH5ConfigGetBaseConfig(params)
        if (result.code === 1) {
            setBaseConfig(result.data);
            // message.success('获取成功')
        }
        Toast.clear()
    }


    return (
        <>
            <NavBar onBack={()=>{navigate(`/h5/index`)}}>🔥闪兑TRX</NavBar>
            <Divider style={{margin:0,marginBottom:0}} />
            <Card title=''>
                💹实时兑换汇率 <br/>
                ⏰更新时间：{baseConfig.up_time}<br/><br/>
                💰2 USDT ≈ {baseConfig.exchange_rate*2-baseConfig.exchange_rate*2*info.shandui_usdt_profit/100} TRX<br/>
                💰10 USDT ≈ {baseConfig.exchange_rate*10-baseConfig.exchange_rate*10*info.shandui_usdt_profit/100} TRX<br/>
                💰20 USDT ≈ {baseConfig.exchange_rate*20-baseConfig.exchange_rate*20*info.shandui_usdt_profit/100} TRX<br/>
                💰30 USDT ≈ {baseConfig.exchange_rate*30-baseConfig.exchange_rate*30*info.shandui_usdt_profit/100} TRX<br/>
                💰40 USDT ≈ {baseConfig.exchange_rate*40-baseConfig.exchange_rate*40*info.shandui_usdt_profit/100} TRX<br/>
                💰50 USDT ≈ {baseConfig.exchange_rate*50-baseConfig.exchange_rate*50*info.shandui_usdt_profit/100} TRX<br/>
                <br/>
                ⚠️如有超额或者不到账请联系客服退款 <br/>
                请向下方地址转入USDT，3秒内自动回款<br/>
                🔻自动兑换地址(点击可复制)🔻<br/>
                <div style={{padding:"5px 0",color:"blue",fontSize:"16px",fontWeight: "bold"}} onClick={()=>copyTextToClipboard(info.payment_shandui_address)}>{info.payment_shandui_address}</div>
                ⚠️温馨提示⚠️<br/>
                - 切勿用交易所钱包转账<br/>
                - 暂时只支持大于 2 USDT 起兑换<br/>
                - 如有转错金额或其他疑问，请联系客服
            </Card>
        </>
    )
}