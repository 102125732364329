import {useEffect, useState} from "react";
import {Card, Divider, NavBar} from "antd-mobile";
import storeage from "../../../utils/storage";
import {useNavigate} from "react-router-dom";
import {copyTextToClipboard} from "../../../utils/define";


export default function Index() {
    const navigate = useNavigate()
    const [info, setInfo] = useState(false);
    document.title = '新的页面标题';
    useEffect(() => {
        let config = storeage.getKey("config")
        setInfo(config);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (<>
        <NavBar onBack={()=>{navigate(`/h5/index`)}}>🔋能量闪租</NavBar>
        <Divider style={{margin:0,marginBottom:0}} />
        <Card title=''>
            ⚡️ 购买1小时能量闪租转账地址(点击复制)<br/>
            <div style={{padding:"5px 0",color:"blue",fontSize:"16px",fontWeight: "bold"}} onClick={()=>copyTextToClipboard(info.payment_tenancy_address)}>{info.payment_tenancy_address}</div>
            ⚡️ 转账 {1*info.tenancy_time_price} TRX = 获取购买 1 笔<br/>
            ⚡️ 转账 {2*info.tenancy_time_price} TRX = 获取购买 2 笔<br/>
            ⚡️ 转账 {4*info.tenancy_time_price} TRX = 获取购买 4 笔<br/>
            ⚡️ 转账 {6*info.tenancy_time_price} TRX = 获取购买 6 笔<br/>
            ⚡️ 转账 {20*info.tenancy_time_price} TRX = 获取购买 20 笔<br/>
            <br/>
            🔺以此类推，最多 {info.tenancy_time_max} 笔🔺<br/>
            <br/>
            ⚠️温馨提示⚠️<br/>
            - 购买1笔能量数为65000<br/>
            - 向无U的地址转账, 需要双倍的能量<br/>
            - 请在1小时内使用能量，否则会过期回收<br/>
            - 务必转入{info.tenancy_time_price}TRX的倍数，否则会租用失败
            ----------------------------------------------------------<br/>
            <b>请点击您需要操作的选项</b>",
        </Card>
    </>)
}