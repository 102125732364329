import {ajax} from "./request";

let baseUrl = 'https://1trxzz.top:7669';
// let baseUrl = 'http://127.0.0.1:7668';
if (process.env.REACT_APP_PROXY_URL === 'build') {
    baseUrl = 'http://83.229.121.190:7669';
}

export const ajaxH5ConfigGetInfo = param => ajax(baseUrl+'/h5/config/get-info', param, 'POST');
export const ajaxH5ConfigGetBaseConfig = param => ajax(baseUrl+'/h5/config/get-base-config', param, 'POST');
