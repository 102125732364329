import {Toast} from "antd-mobile";

export function GetCategoryName(category) {
    let name = "TRX"
    if (category === 2) {
        name = "USDT"
    }
    return name
}

export function GetStatusName(status) {
    let name = '关闭'
    if (status === 1) {
        name = "开启"
    }
    return name
}

export function GetRechargeStatusName(status) {
    let startArr = {0:"申请",1:"成功",2:"失败",3:"超时"}
    return startArr[status]
}


export function GetTenancyStatusName(status) {
    let startArr = {0:"申请",1:"开启",2:"失败",3:"停止",4:"完成",5:"支付成功",6:"合并订单"}
    return startArr[status]
}

export function GetBalanceStatusName(status) {
    let startArr = {0:"默认",1:"充值",2:"闪兑",3:"小时能量",4:"笔数",5:"代扣",98:"退款",99:"代理商充值"}
    return startArr[status]
}

export function GetTronOrderTypesName(status) {
    let startArr = {0:"默认",1:"充值",2:"闪兑",3:"小时能量",4:"笔数",5:"代扣",98:"退款",99:"代理商充值"}
    return startArr[status]
}

export function GetTronOrderStatusName(status) {
    let startArr = {0:"未处理",1:"处理成功",2:"处理失败",3:"处理失败",4:"处理失败"}
    return startArr[status]
}


export function GetWhetherName(status) {
    let name = '否'
    if (status === 1) {
        name = "是"
    }
    return name
}
export function GetValidateAddress() {
    return [
        {required: true, message: '地址不能为空'},
        {len: 34, message: '请输入正确的地址，必须满足 34位'},
        {pattern: /^[a-zA-Z0-9]+$/, message: '必须是英文,数字'},
    ]
}
export function GetValidateNumber(min,max) {
    return [
        {required: true, message: '不能为空'},
        {min: min, message: '请输入正确的数字，必须大于'+min},
        {max: max, message: '请输入正确的数字，必须小于'+max},
        {type:'number', message: '必须是数字'},
    ]
}

export function GetValidateString(min,max) {
    return [
        {required: true, message: '不能为空'},
        {min: min, message: '请输入正确的内容，必须大于'+min+'位'},
        {max: max, message: '请输入正确的内容，必须小于'+max+'位'},
        {pattern: /^[a-zA-Z0-9]+$/, message: '必须是字符串'},
    ]
}


export async function copyTextToClipboard(text) {
    try {
        Toast.show({content: '文本已复制到剪贴板', position: 'bottom',})
        await navigator.clipboard.writeText(text);
        console.log('文本已复制到剪贴板');
    } catch (err) {
        Toast.show({content: '无法复制文本: '+err, position: 'bottom',})
        console.error('无法复制文本: ', err);
    }
}