import React from 'react';
import {Route, Routes} from "react-router-dom";
import H5Index from "../../h5/index";
import H5ExchangeTRX  from "../../h5/exchangeTRX";
import H5Tenancy  from "../../h5/tenancy";
import H5TenancyPen  from "../../h5/tenancyPen";

export default function Index(){

    return <>
        <Routes>
            <Route path='/index' element={<H5Index/>}/>
            <Route path='/exchange-trx' element={<H5ExchangeTRX/>}/>
            <Route path='/tenancy' element={<H5Tenancy/>}/>
            <Route path='/tenancy-pen' element={<H5TenancyPen/>}/>
        </Routes>
    </>
}