import {Card, Divider, NavBar, Tag} from "antd-mobile";
import {useEffect, useState} from "react";
import storeage from "../../../utils/storage";
import {useNavigate} from "react-router-dom";
import {copyTextToClipboard} from "../../../utils/define";


export default function Index() {
    const navigate = useNavigate()
    const [info, setInfo] = useState(false);
    document.title = '新的页面标题';
    useEffect(() => {
        let config = storeage.getKey("config")
        setInfo(config);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (<>
        <NavBar onBack={()=>{navigate(`/h5/index`)}}>✏️笔数套餐</NavBar>
        <Divider style={{margin:0,marginBottom:0}} />
        <Card title=''>
            🔋租用能量，转账无需TRX消耗，0手续费！<br/><br/>
            【笔数模式】<br/>
            每次发送2笔，按实际使用情况扣费！<br/>
            消耗1笔（对方有 U )<br/>
            消耗2笔（对方无 U )<br/>
            24小时内未转账，会扣除2笔计数。<br/>
            连续5日未使用自动关闭<br/>
            每次转账消耗0.345TRX（宽带燃烧）<br/><br/>
            ⚡️ 购买️笔数套餐转账地址(点击复制)<br/>
            <div style={{padding:"5px 0",color:"blue",fontSize:"16px",fontWeight: "bold"}} onClick={()=>copyTextToClipboard(info.payment_tenancy_pen_address)}>{info.payment_tenancy_pen_address}</div>
            ✅转U之前,请先购买能量，可以节约TRX。<br/>
            👉每笔单价：{info.tenancy_pen_usdt_price} USDT<br/>
            👉每笔能量：65000<br/>
            请点击地址复制，直接转入USDT，如转入 {info.tenancy_pen_usdt_price*100} USDT，可获得100次<br/>
            自动监控转入地址能量，不足65000时，自动补足能量<br/><br/>


            <Tag round color='#2db7f5'>
                💰10笔（{info.tenancy_pen_trx_price*10} TRX）
            </Tag>
            <Tag round color='#2db7f5'>
                💰20笔（{info.tenancy_pen_trx_price*20} TRX）
            </Tag>
            <Tag round color='#2db7f5'>
                💰50笔（{info.tenancy_pen_trx_price*50} TRX）
            </Tag><br/>
            <Tag round color='#2db7f5'>
                💰100笔（{info.tenancy_pen_trx_price*100} TRX）
            </Tag>
            <Tag round color='#2db7f5'>
                💰200笔（{info.tenancy_pen_trx_price*200} TRX）
            </Tag>
            <Tag round color='#2db7f5'>
                💰500笔（{info.tenancy_pen_trx_price*500} TRX）
            </Tag><br/><br/>


            <Tag round color='#87d068'>
                💰10笔（{info.tenancy_pen_usdt_price*10} USDT）
            </Tag>
            <Tag round color='#87d068'>
                💰20笔（{info.tenancy_pen_usdt_price*20} USDT）
            </Tag>
            <Tag round color='#87d068'>
                💰50笔（{info.tenancy_pen_usdt_price*50} USDT）
            </Tag><br/>
            <Tag round color='#87d068'>
                💰100笔（{info.tenancy_pen_usdt_price*100} USDT）
            </Tag>
            <Tag round color='#87d068'>
                💰200笔（{info.tenancy_pen_usdt_price*200} USDT）
            </Tag>
            <Tag round color='#87d068'>
                💰500笔（{info.tenancy_pen_usdt_price*500} USDT）
            </Tag>
        </Card>
    </>)
}