import React from "react";

import {Button, Result} from "antd";
import {SmileOutlined} from "@ant-design/icons";

export default function Home(){


    return <>
        <Result
            icon={<SmileOutlined />}
            title="Great, we have done all the operations!"
            extra={<Button type="primary">Next</Button>}
        />
    </>
}