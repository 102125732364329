/**
 * 本地存储数据
 */
import Store from "store2"

const exportedObject = {
    saveToken(token, token_expire) {
        Store.set('admin_user_token', token, token_expire)
        Store.set('admin_user_token_expire', token_expire, token_expire)
        return true;
    }, getToken() {
        return Store.get('admin_user_token') || ''
    }, getTokenExpire() {
        return Store.get('admin_user_token_expire') || ''
    }, removeToken() {
        Store.remove('admin_user_token')
        Store.remove('admin_user_token_expire')
        return true;
    }, saveUser(user) {
        Store.set('admin-user', user)
        return true
    }, getUser() {
        return Store.get('admin-user') || ''
    }, removeUser() {
        Store.remove('admin-user')
        return true
    }, saveXzqh(xzqh) {
        Store.set('admin-xzqh', xzqh)
        return true
    },
    saveXzqhName(xzqh) {
        Store.set('admin-xzqh-name', xzqh)
        return true
    },
    getConfig() {
        return Store.get('admin-config') || {}
    },
    getKey(param) {
        return Store.get(param) || ''
    }, setKey(param, value) {
        Store.set(param, value)
    }, removeKey(param) {
        Store.remove(param)
        return true
    },
}

export default exportedObject